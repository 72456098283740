.Pool-option-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px;
  background-color: #252527;
  border-radius: 12px;
  width: 208px;
  border: 2px solid #252527;

  img {
  }

  h6 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: white;
  }

  &:hover {
    border: 2px solid #3b87f7;

    h6 {
      color: #3b87f7;
    }
  }

  @media (max-width: 395px) {
    width: 100%;
  }
}

.pool-option-card-active {
  background-color: #3b87f7 !important;
  border: 2px solid #3b87f7;

  &:hover {
    h6 {
      color: white !important;
    }
  }
}

.token-price-tag {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #9b9ca3;
}

.ant-input-number-input {
  height: 29px !important;
}

.coin-icon-size {
  height: 24px;
  width: 24px;
}

.pool-cards-width {
  width: 100%;

  @media (min-width: 960px) {
    width: 416px;
  }

  @media (min-width: 1280px) {
    width: 378px;
  }

  @media (min-width: 1440px) {
    width: 432px;
  }

  @media (min-width: 1920px) {
    width: 440px;
  }
}

.pie-in-create-pool-model {
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.icon-size-pool-card {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;

  @media (max-width: 600px) {
    width: 46px;
    height: 46px;
  }
}

.ant-modal-root .ant-modal {
  max-width: calc(100vw - 40px);
}

.ant-select .ant-select-arrow {
  color: white;
}

.AddLiquidityFeeTires {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 8px;
  flex: 1;
  border: 2px solid transparent;
  cursor: pointer;
}

.remove-liquidity-heading-percent {
  padding: 0px;
  margin: 0px;
  color: #3b87f7;
  font-size: 32px;
  font-weight: 700;
  font-family: Inter;
  margin-bottom: 12px;
}

.remove-liquidity-percent-select {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.07);
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  color: #9b9ca3;
  border: 2px solid transparent;
  cursor: pointer;
}

.remove-liquidity-percent-select-active {
  color: white !important;
  border: 2px solid #3b87f7 !important;
}

.your-position-container {
  padding: 16px;
  gap: 16px;
  flex: 1;
  background-color: #37373c;
  border-radius: 12px;
  flex-direction: column;
}

.SwapInputNumber.ant-input-number-sm input.ant-input-number-input {
  text-align: right;
  padding: 0;
  font-weight: 600 !important;
}

.swap-select .ant-select-selector {
  padding: 0 !important;
  width: 150px !important;
}

.swap-select.ant-select-show-arrow .ant-select-selection-item {
  font-weight: 600 !important;
}

.swap-image {
  @media (max-width: 725px) {
    width: 100%;
  }
}

.hero-left-image {
  height: 550px;
  width: 550px;
  position: absolute;
  left: -170px;
  top: -120px;
  transform: rotate(35deg);
}

.hero-right-image {
  height: 550px;
  width: 550px;
  position: absolute;
  right: -180px;
  top: -130px;
  transform: rotate(-35deg);
}

@media (max-width: 960px) {
  .hero-left-image {
    height: 350px;
    width: 350px;
    left: -140px;
    top: -100px;
  }

  .hero-right-image {
    height: 350px;
    width: 350px;
    right: -120px;
    top: -100px;
  }
}

.custom-input-slippage:focus {
  outline: none;
  border-color: transparent;
}

.token-list-component:hover {
  background-color: #2e2e32;
}

.token-list-component-disabled {
  background-color: #5d5d61;
}

.App {
  .pools-overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    isolation: isolate;
    z-index: 9000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(24, 24, 25, 0.8);
    opacity: 0;
    pointer-events: none;
    transition: all 0.31s 0s ease-out;

    &.IsPoolsOverlayOpen {
      opacity: 1;
      pointer-events: all;
    }

    .confirm-modal-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 28px;
      border: var(--Size-Line-Width-lineWidth, 1px) solid
        rgba(255, 255, 255, 0.1);
      background: var(--Secondary-700, #252527);
      box-shadow: 0px 4px 28.5px 0px rgba(0, 0, 0, 0.5);
      max-width: 465px;
      gap: 24px;
      padding: 24px;
      margin: 0 16px;

      h2 {
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin: 0;
      }

      p {
        color: #7c7c82;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }

      .btn-wrap {
        border-top: var(--Size-Line-Width-lineWidth, 1px) solid
          var(--Secondary-600, #37373c);
        width: 100%;

        .btn {
          width: 100%;
          padding: 0 26px;
          height: 47px;
          border-radius: 18px;
          background: #37373c;
          border: 0;
          margin-top: 14px;
          cursor: pointer;

          p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }

    .banner-wrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      @media (max-width: 1280px) {
        left: -15vw;
      }

      @media (max-width: 960px) {
        left: -310px;
      }

      @media (max-width: 600px) {
        left: -80vw;
      }

      @media (max-width: 520px) {
        left: -520px;
      }

      .banner-element {
        position: absolute;
        display: flex;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(3px);
        transform-origin: left;

        .img-wrap {
          display: flex;
          gap: 0;
          pointer-events: none;

          img {
            height: 64px;
          }

          @keyframes TickerMoveLeft {
            from {
              transform: translateX(0%);
            }

            to {
              transform: translateX(-50%);
            }
          }

          @keyframes TickerMoveRight {
            from {
              transform: translateX(-50%);
            }

            to {
              transform: translateX(0%);
            }
          }
        }

        &.TickerMoveLeft {
          .img-wrap {
            animation: TickerMoveLeft 130s linear infinite forwards;
          }
        }

        &.TickerMoveRight {
          .img-wrap {
            animation: TickerMoveRight 130s linear infinite forwards;
          }
        }

        &.Banner1 {
          transform: rotate(22.8deg);
          top: 293px;
          left: -10vw;
          z-index: 3;
        }

        &.Banner2 {
          transform: rotate(-9.87deg);
          top: 148px;
          left: -10vw;
          z-index: 4;
        }

        &.Banner3 {
          transform: rotate(17.89deg);
          top: -162px;
          left: -2vw;
          z-index: 3;
        }

        &.Banner4 {
          transform: rotate(-22.06deg);
          top: 918px;
          left: 8.5vw;
          z-index: 2;
        }

        &.Banner5 {
          transform: rotate(3.51deg);
          top: 426px;
          left: -10vw;
          z-index: 1;
        }

        &.Banner6 {
          transform: rotate(-5.3deg);
          top: 274px;
          left: -10vw;
          z-index: 1;
        }
      }
    }
  }
}

.centered-alert {
  position: fixed;
  top: 20px; /* Adjust this value to move it lower or higher */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000; /* High z-index to be above all other components */
  width: auto;
}

.loading-skeleton {
  width: 100%;
  min-width: 100%;
}

.custom-select {
  border-radius: 8px 0 0 8px;
}
.custom-select .ant-select-selector {
  border-radius: 8px 0 0 8px;
}
.custom-select .ant-select-selector.ant-select-selector {
  border: 1px solid #b0b0b0;
  border-right: 0;
}

/* Optional: Change border radius when the select is focused */
.custom-select .ant-select-selector.ant-select-selector:hover,
.custom-select .ant-select-selector.ant-select-focused {
  border-radius: 8px 0 0 8px;
  border-color: #40a9ff;
}

.custom-select-pop {
  width: 200px !important;
  padding: 8px;
  border-radius: 12px;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-weight: 600;
  font-size: 18px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #37373c;
}
